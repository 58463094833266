import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './auth'

const UserContext = createContext()

function UserProvider(props) {
  const { data } = useAuth()
  return <UserContext.Provider value={data} {...props} />
}

function useUser() {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }

  return context
}

UserProvider.propTypes = {
  props: PropTypes.object
}

export { UserProvider, useUser }
