import React from 'react'
import PropTypes from 'prop-types'
import { AuthProvider } from './auth'
import { UserProvider } from './user'

function AuthProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  )
}

AuthProviders.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default AuthProviders
